import Vue from "vue";
import VueI18n from "vue-i18n";
const AppConfig = JSON.parse(localStorage.getItem("AppConfig"));
// use VueI18n with vue 
Vue.use(VueI18n);

// get language from sessionstorage and load local text values
function loadLocaleTexts() {
  const texts = {};
  const languages = JSON.parse(sessionStorage.getItem("languages"));
  if (languages) {
    languages.map((language) => {
      texts[language.LangText] = JSON.parse(
        sessionStorage.getItem(language.LangText + "-localeTexts")
      );
    });
  }
  return texts;
}

// export VueI18n 
export default new VueI18n({
  locale: sessionStorage.getItem("lang")
    ? sessionStorage.getItem("lang")
    : sessionStorage.setItem("lang", AppConfig.I18N_LOCALE),
  localeLang: sessionStorage.getItem("localeLang")
    ? sessionStorage.getItem("localeLang")
    : sessionStorage.setItem("localeLang", AppConfig.I18N_LOCALE_LANG),
  fallbackLocale: AppConfig.I18N_FALLBACK_LOCALE,
  messages: loadLocaleTexts(),
});
